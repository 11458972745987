<template>
  <view-item title="盘点">
    <template #operation>
      <en-button type="primary" @click="operation.add.click">创建单据</en-button>

      <button-ajax v-if="form.data.id" :disabled="form.disabled || form.loading" :method="operation.submit.click"> 保存 </button-ajax>

      <button-ajax v-if="form.data.id" :disabled="form.disabled || form.loading" :method="operation.commit.click"> 保存并提交 </button-ajax>

      <en-dropdown v-if="form.data.id" @command="operation.option.command">
        <en-button type="primary">操作</en-button>
        <template #dropdown>
          <en-dropdown-item command="logs">单据历史</en-dropdown-item>
          <en-dropdown-item command="export">导出</en-dropdown-item>
          <en-dropdown-item v-if="form.data.status?.code === 'P'" command="delete">删除</en-dropdown-item>
        </template>
      </en-dropdown>
    </template>

    <template #manifest>
      <table-manifest
        code="STKSEFD"
        :ajax="{ action: 'GET /enocloud/stock/taking/query', params: (params) => (params.payload = { rsexp: 'id,serialNo,status' }) }"
        :props="{ start: 'startDate', end: 'endDate' }"
        :routes="[
          { path: '/accessory/inventory/adjustment', name: '调价单' },
          { path: '/accessory/stock/taking', name: '盘点单' }
        ]"
        @expand-click="dialog.visible = true"
        @row-click="manifest.row.click"
      >
        <template #form="{ data }">
          <en-form-item label="盘点单号">
            <en-input v-model="data.serialNo"></en-input>
          </en-form-item>
        </template>
      </table-manifest>
    </template>

    <view-item-content :disabled="!form.data.id">
      <en-card v-loading="form.loading" body-class="flex flex-col gap-6">
        <div class="flex gap-4">
          <span>业务类型：配件盘点</span>
          <span>盘点仓库：{{ form.data.warehouse?.name }}</span>
        </div>

        <div class="flex items-center gap-6">
          <span>{{ form.data.preparedBy?.name }}</span>
          <en-tag>{{ form.data.status?.message }}</en-tag>
          <span>单号：{{ form.data.serialNo }}</span>
          <span>{{ formatDate(form.data.preparedDatetime) }}</span>
          <span>{{ form.data.comment }}</span>
        </div>
      </en-card>

      <en-card v-loading="form.loading" class="flex-1 overflow-auto" body-class="h-full">
        <flex-box>
          <template #default="{ height }">
            <en-tabs v-model="tabs.active">
              <en-tab-pane
                label="配件"
                name="goods"
                :badge="form.data.stockTakingGoods?.length"
                :style="{ height: height - 55 + 'px', overflow: 'auto' }"
                class="gap-6"
              >
                <div class="flex gap-4">
                  <select-maintain
                    v-if="form.data.warehouse?.id"
                    :ajax="{
                      action: 'GET /enocloud/common/goods',
                      params: (params, value) => (params.payload = { name: value, inMaterialOnly: 'Y', warehouseId: form.data.warehouse?.id })
                    }"
                    :props="{
                      label: 'name',
                      value: '',
                      disabled: (option: EnocloudInventoryDefinitions['InventoryDto']) =>
                        Boolean(form.data.stockTakingGoods?.find((item) => item.goods?.id === option.goods?.id))
                    }"
                    value-key="id"
                    placeholder="请选择配件"
                    remote
                    class="w-110"
                    :disabled="form.disabled"
                    @change="form.stockTakingGoods.operation.goods.change"
                  ></select-maintain>

                  <en-button type="primary" :disabled="form.disabled" @click="form.stockTakingGoods.operation.batch.click"> 批量添加配件 </en-button>
                </div>

                <flex-box>
                  <template #default="{ height }">
                    <en-table :data="form.data.stockTakingGoods" :height="height" :loading="form.loading">
                      <en-table-column fixed label="操作">
                        <template #default="{ $index }: { $index: number }">
                          <en-button type="primary" link @click="form.stockTakingGoods.operation.delete.click($index)">删除</en-button>
                        </template>
                      </en-table-column>
                      <en-table-column fixed label="序号" type="index" width="80"></en-table-column>
                      <en-table-column fixed label="编码" prop="goods.serialNo" width="150"> </en-table-column>
                      <en-table-column fixed label="名称" prop="goods.name" width="120"></en-table-column>
                      <en-table-column label="OE号" prop="goods.oem"></en-table-column>
                      <en-table-column label="规格" width="180">
                        <template #default="{ row }: { row: EnocloudInventoryDefinitions['StockTakingGoodsDto'] }">
                          <select-maintain
                            v-model="row.goodsSpecification"
                            :disabled="form.disabled"
                            :options="row.goods?.specifications"
                            :props="{ label: 'name', value: '' }"
                            @change="form.stockTakingGoods.goodsSpecification.change(row)"
                            value-key="id"
                            :clearable="false"
                            class="w-full"
                          ></select-maintain>
                        </template>
                      </en-table-column>
                      <en-table-column label="基本单位换算" width="150">
                        <template #default="{ row }: { row: EnocloudInventoryDefinitions['StockTakingGoodsDto'] }">
                          {{ `${row.goodsSpecification?.weight}${row.goods?.warehouseUnit}` }}
                        </template>
                      </en-table-column>
                      <en-table-column label="账面数量" prop="originalCount" width="100">
                        <template #default="{ row }: { row: EnocloudInventoryDefinitions['StockTakingGoodsDto'] }">
                          {{ formatNumber(row.originalCount, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="账面金额" width="100">
                        <template #default="{ row }: { row: EnocloudInventoryDefinitions['StockTakingGoodsDto'] }">
                          {{
                            formatMoney(
                              row.batch?.count
                                ? row.batch.count * (row.batch.primeCost ?? 0)
                                : (row.originalCount ?? 0) * (row.originalPrimeCost ?? 0) ?? 0,
                              2
                            )
                          }}
                        </template>
                      </en-table-column>
                      <en-table-column label="批次" width="180">
                        <template #default="{ row }: { row: EnocloudInventoryDefinitions['StockTakingGoodsDto'] }">
                          <select-maintain
                            v-model="row.batch"
                            :disabled="form.disabled"
                            :options="row.goods?.batches"
                            :props="{
                              label: 'batchNo',
                              value: '',
                              disabled: (option: EnocloudInventoryDefinitions['InventoryDto']) =>
                                Boolean(
                                  form.data.stockTakingGoods?.find(
                                    (item) =>
                                      item.goods?.id === row.goods?.id &&
                                      form.data.stockTakingGoods.map((inner) => inner.batch?.id).includes(option.id)
                                  )
                                )
                            }"
                            value-key="id"
                            class="w-full"
                          ></select-maintain>
                        </template>
                      </en-table-column>
                      <en-table-column label="盈亏数量" width="100">
                        <template #default="{ row }: { row: EnocloudInventoryDefinitions['StockTakingGoodsDto'] }">
                          <en-input-number
                            v-model="row.adjustingCount"
                            :min="row.originalCount && row.originalCount > 0 ? -row.originalCount : 0"
                            :disabled="form.disabled"
                            @change="form.stockTakingGoods.originalCount.change(row)"
                            class="w-full"
                          ></en-input-number>
                        </template>
                      </en-table-column>
                      <en-table-column label="盈亏单价" width="100">
                        <template #default="{ row }: { row: EnocloudInventoryDefinitions['StockTakingGoodsDto'] }">
                          <en-input-number
                            v-model="row.primeCost"
                            :disabled="form.disabled"
                            @change="form.stockTakingGoods.primeCost.change(row)"
                            class="w-full"
                          ></en-input-number>
                        </template>
                      </en-table-column>
                      <en-table-column label="盈亏金额" width="100">
                        <template #default="{ row }: { row: EnocloudInventoryDefinitions['StockTakingGoodsDto'] }">
                          {{ formatMoney((row.primeCost ?? 0) * (row.originalCount ?? 0), 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="实际数量" width="100">
                        <template #default="{ row }: { row: EnocloudInventoryDefinitions['StockTakingGoodsDto'] }">
                          <en-input-number
                            v-model="row.actualCount"
                            :disabled="form.disabled"
                            @change="form.stockTakingGoods.actualCount.change(row)"
                            class="w-full"
                          ></en-input-number>
                        </template>
                      </en-table-column>
                      <en-table-column label="实际单价" width="100">
                        <template #default="{ row }: { row: EnocloudInventoryDefinitions['StockTakingGoodsDto'] }">
                          {{ formatMoney(row.actualCost, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="实际金额" width="100">
                        <template #default="{ row }: { row: EnocloudInventoryDefinitions['StockTakingGoodsDto'] }">
                          {{ formatMoney(row.actualAmount, 2) }}
                        </template>
                      </en-table-column>
                      <en-table-column label="适用车型" width="100">
                        <template #default="{ row }: { row: EnocloudInventoryDefinitions['StockTakingGoodsDto'] }">
                          {{ row.goods?.primaryVehicleSpec.join(',') }}
                        </template>
                      </en-table-column>
                      <en-table-column label="品质类型" prop="goods.type.message" width="150"></en-table-column>
                      <en-table-column label="品牌" prop="goods.brand"></en-table-column>
                      <en-table-column label="产地" prop="goods.placeOfProduction"></en-table-column>
                      <en-table-column label="配件条码" prop="goods.barcodes" width="100"></en-table-column>
                      <en-table-column label="配件型号" prop="goods.model" width="100"></en-table-column>
                      <en-table-column label="备注">
                        <template #default="{ row }: { row: EnocloudInventoryDefinitions['StockTakingGoodsDto'] }">
                          <en-input v-model="row.comment" :disabled="form.disabled"></en-input>
                        </template>
                      </en-table-column>
                    </en-table>
                  </template>
                </flex-box>
              </en-tab-pane>
            </en-tabs>
          </template>
        </flex-box>
      </en-card>
    </view-item-content>
  </view-item>

  <stock-taking-dialog v-model="dialog.visible"></stock-taking-dialog>

  <stock-taking-detail v-model="detail.visible" :data="form.data" @confirm="detail.confirm"></stock-taking-detail>

  <stock-taking-logs v-model="logs.visible" :data="form.data"></stock-taking-logs>

  <goods-selection
    v-model="selection.visible"
    type="stock-taking"
    @confirm="selection.confirm"
    :params="{ inMaterialOnly: 'Y', warehouseId: form.data.warehouse?.id }"
  ></goods-selection>
</template>

<script lang="ts">
import StockTakingDetail from '@accessory/components/stock-taking-detail.vue'
import StockTakingDialog from '@accessory/components/stock-taking-dialog.vue'
import StockTakingLogs from '@accessory/components/stock-taking-logs.vue'

import { GoodsSelection, type GoodsSelectionOption } from '@enocloud/business'
import { calculator } from '@enocloud/utils'

import { cloneDeep } from 'lodash-es'

const formDataGoodsInit = (props: Partial<EnocloudInventoryDefinitions['StockTakingGoodsDto']>) => {
  return Object.assign({ originalCount: 0 }, props) as EnocloudInventoryDefinitions['StockTakingGoodsDto']
}

export default factory({
  components: { StockTakingDetail, StockTakingDialog, StockTakingLogs, GoodsSelection },

  config: {
    children: {
      operation: {
        add: {
          click() {
            this.form.init()
            this.detail.visible = true
          }
        },
        submit: {
          async click() {
            await this.form.submit()
            return this.form.get()
          }
        },
        commit: {
          async click() {
            await this.form.submit()
            await this.form.audit()
            return this.form.get()
          }
        },
        option: {
          async command(option: string) {
            await this.dirtyCheck('form')
            switch (option) {
              case 'reject':
                break
              case 'logs':
                this.logs.visible = true
                break
              case 'export':
                break
              case 'delete':
                await this.form.delete()
                this.form.init()
                break
            }
          }
        }
      },
      manifest: {
        row: {
          click(row: EnocloudInventoryDefinitions['StockCostAdjustmentDto']) {
            this.form.init()
            this.form.data.id = row.id
            this.form.get()
          }
        }
      },
      tabs: {
        active: 'goods'
      },
      form: {
        ajax: {
          get: {
            action: 'GET /enocloud/stock/taking/:stockTakingId',
            data: 'object',
            init: true,
            loading: true,
            dirty: true,
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          submit: {
            action: 'PUT /enocloud/stock/taking',
            loading: true,
            params(params) {
              params.payload = this.form.data
            }
          },
          audit: {
            action: 'POST /enocloud/stock/taking/:stockTakingId/audit',
            params(params) {
              params.paths = [this.form.data.id]
            }
          },
          delete: {
            action: 'DELETE /enocloud/stock/taking/:stockTakingId',
            params(params) {
              params.paths = [this.form.data.id]
            }
          }
        },
        computed: {
          summary() {
            return {
              count: 0,
              amount: 0
            }
          },
          disabled() {
            return this.form.data.status?.code === 'A'
          }
        },
        children: {
          stockTakingGoods: {
            operation: {
              goods: {
                change(value: EnocloudCommonDefinitions['GoodsDto']) {
                  this.form.data.stockTakingGoods ??= []

                  const { id, specifications = [], batches = [] } = value

                  for (const batch of batches) {
                    if (
                      this.form.data.stockTakingGoods
                        .filter((item) => item.goods?.id === id)
                        .map((item) => item.batch?.id)
                        .includes(batch.id)
                    )
                      return

                    const goodsSpecification = Object.assign({}, specifications.find((item) => item.defaultStockTaking?.value) ?? specifications[0], {
                      goods: value
                    }) as EnocloudServiceDefinitions['GoodsSpecificationDto']

                    const init = formDataGoodsInit({
                      goods: value,
                      goodsSpecification,
                      batch,
                      originalCount: batch.count
                    })

                    this.form.data.stockTakingGoods.push(init)
                  }
                }
              },
              delete: {
                click(index: number) {
                  this.form.data.stockTakingGoods?.splice(index, 1)
                }
              },
              batch: {
                click() {
                  this.selection.visible = true
                }
              }
            },
            goodsSpecification: {
              change(row: EnocloudInventoryDefinitions['StockTakingGoodsDto']) {
                const goods = cloneDeep(row.goods)
                row.goodsSpecification!.goods = goods
              }
            },
            batches: {
              change(row: EnocloudInventoryDefinitions['StockTakingGoodsDto']) {}
            }
          }
        }
      },
      dialog: {
        visible: false
      },
      detail: {
        visible: false,
        confirm(res: number | undefined) {
          if (res) this.form.data.id = res
          this.form.get()
        }
      },
      selection: {
        visible: false,
        confirm(rows: GoodsSelectionOption[]) {
          for (const row of rows) {
            const exist = this.form.data.stockTakingGoods.find((item) => item.goods?.id === row.goods.id)
            if (!exist) {
              const init = {
                goods: row.goods,
                goodsSpecification: Object.assign({}, row.goodsSpecification, { goods: row.goods }),
                batch: row.batch
              } as EnocloudInventoryDefinitions['StockCostAdjustmentGoodsDto']
              this.form.data.stockTakingGoods.push(init)
            }
          }
        }
      },
      logs: {
        visible: false
      },
      adjustment: {
        visible: false,
        confirm(res: number | undefined) {}
      }
    }
  }
})
</script>
